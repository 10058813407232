import * as React from 'react';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';


const Search = ({onChange, value}) => {
    return (
        <FormControl sx={{ width: { xs: '100%', sm: 'auto' } }} variant="outlined">
        <OutlinedInput
          onChange={onChange}
          value={value}
          size="small"
          id="search"
          placeholder="Search…"
          startAdornment={
            <InputAdornment position="start" sx={{ color: 'text.primary' }}>
              <SearchRoundedIcon fontSize="small" />
            </InputAdornment>
          }
          inputProps={{
            'aria-label': 'search',
          }}
        />
      </FormControl>
      
    );
};

export default Search;
