import Chip from '@mui/material/Chip';
const { DateTime } = require('luxon');

function renderStatus(status) {
    const colors = {
        Online: 'success',
        Offline: 'failed',
    };

    if(status === 'success'){
        return <Chip label={status} sx={{ border: '1px solid green', backgroundColor: 'white' }}  size="small" />;
    }
    else{
        return <Chip label={status} sx={{ border: '1px solid red', backgroundColor: 'white' }}  size="small" />;
    }
    
}
function renderDate(value) {
    console.log(value)
    const date = DateTime.fromISO(value);
    return date.toFormat('dd/MM/yyyy HH:mm');
}

//export const columns = 
export const getColumns = (handleEdit, handleView) => {

    return [

        {
            field: 'deploymentId',
            headerName: 'Deployment Id',
            headerAlign: 'center',
            align: 'center',
            flex: 1,
            minWidth: 80,
        },
        {
            field: 'serviceName',
            headerName: 'Service Name',
            headerAlign: 'center',
            align: 'center',
            flex: 1,
            minWidth: 80,
        },
        {
            field: 'buildStatus',
            headerName: 'Build Status',
            headerAlign: 'center',
            align: 'center',
            flex: 1,
            minWidth: 80,
            renderCell: (params) => renderStatus(params.value),

        },
        {
            field: 'deployedTo',
            headerName: 'Deployed To',
            headerAlign: 'center',
            align: 'center',
            flex: 1,
            minWidth: 80,
        },
        {
            field: 'createdAt',
            headerName: 'Created At',
            headerAlign: 'center',
            align: 'center',
            flex: 1,
            minWidth: 80,
            renderCell: (params) => renderDate(params.value),
        },
        {
            field: 'createdBy',
            headerName: 'Created By',
            headerAlign: 'center',
            align: 'center',
            flex: 1,
            minWidth: 80,            
        },

    ];
}



export const rows = [
    {
        id: 1,
        deploymentId: 1,
        serviceName: "UserService",
        buildStatus: "success",
        deployedTo: "prod",
        createdAt: "2024-10-15T10:00:00Z",
        createdBy: "Alice Johnson"
    },
    {
        id: 2,
        deploymentId: 2,
        serviceName: "OrderService",
        buildStatus: "failed",
        deployedTo: "stage",
        createdAt: "2024-10-16T11:15:00Z",
        createdBy: "Bob Smith"
    },
    {
        id: 3,
        deploymentId: 3,
        serviceName: "PaymentGateway",
        buildStatus: "success",
        deployedTo: "dev",
        createdAt: "2024-10-17T09:30:00Z",
        createdBy: "Charlie Brown"
    },
    {
        id: 4,
        deploymentId: 4,
        serviceName: "InventoryService",
        buildStatus: "success",
        deployedTo: "prod",
        createdAt: "2024-10-18T08:45:00Z",
        createdBy: "Diana Prince"
    },
    {
        id: 5,
        deploymentId: 5,
        serviceName: "NotificationService",
        buildStatus: "failed",
        deployedTo: "stage",
        createdAt: "2024-10-19T14:00:00Z",
        createdBy: "Ethan Hunt"
    },
    {
        id: 6,
        deploymentId: 6,
        serviceName: "UserProfileService",
        buildStatus: "success",
        deployedTo: "dev",
        createdAt: "2024-10-20T13:15:00Z",
        createdBy: "Fiona Gallagher"
    },
    {
        id: 7,
        deploymentId: 7,
        serviceName: "AnalyticsService",
        buildStatus: "success",
        deployedTo: "prod",
        createdAt: "2024-10-21T12:30:00Z",
        createdBy: "George Williams"
    },
    {
        id: 8,
        deploymentId: 8,
        serviceName: "ReportingService",
        buildStatus: "failed",
        deployedTo: "stage",
        createdAt: "2024-10-22T11:45:00Z",
        createdBy: "Hannah Scott"
    },
    {
        id: 9,
        deploymentId: 9,
        serviceName: "AuthenticationService",
        buildStatus: "success",
        deployedTo: "dev",
        createdAt: "2024-10-23T10:00:00Z",
        createdBy: "Ian Wright"
    },
    {
        id: 10,
        deploymentId: 10,
        serviceName: "SearchService",
        buildStatus: "success",
        deployedTo: "prod",
        createdAt: "2024-10-24T09:15:00Z",
        createdBy: "Jane Doe"
    },
    {
        id: 11,
        deploymentId: 11,
        serviceName: "PaymentService",
        buildStatus: "failed",
        deployedTo: "stage",
        createdAt: "2024-10-25T14:30:00Z",
        createdBy: "Kevin Brown"
    },
    {
        id: 12,
        deploymentId: 12,
        serviceName: "CacheService",
        buildStatus: "success",
        deployedTo: "dev",
        createdAt: "2024-10-26T13:45:00Z",
        createdBy: "Laura Davis"
    },
    {
        id: 13,
        deploymentId: 13,
        serviceName: "MessagingService",
        buildStatus: "success",
        deployedTo: "prod",
        createdAt: "2024-10-27T12:00:00Z",
        createdBy: "Mike Johnson"
    },
    {
        id: 14,
        deploymentId: 14,
        serviceName: "GatewayService",
        buildStatus: "failed",
        deployedTo: "stage",
        createdAt: "2024-10-28T11:15:00Z",
        createdBy: "Nina Taylor"
    },
    {
        id: 15,
        deploymentId: 15,
        serviceName: "ContentService",
        buildStatus: "success",
        deployedTo: "dev",
        createdAt: "2024-10-29T10:30:00Z",
        createdBy: "Oscar Wilson"
    },
    {
        id: 16,
        deploymentId: 16,
        serviceName: "EmailService",
        buildStatus: "success",
        deployedTo: "prod",
        createdAt: "2024-10-30T09:45:00Z",
        createdBy: "Pamela White"
    },
    {
        id: 17,
        deploymentId: 17,
        serviceName: "UserManagementService",
        buildStatus: "failed",
        deployedTo: "stage",
        createdAt: "2024-10-31T14:00:00Z",
        createdBy: "Quinn Brown"
    },
    {
        id: 18,
        deploymentId: 18,
        serviceName: "SessionService",
        buildStatus: "success",
        deployedTo: "dev",
        createdAt: "2024-11-01T13:15:00Z",
        createdBy: "Rachel Green"
    },
    {
        id: 19,
        deploymentId: 19,
        serviceName: "ProductService",
        buildStatus: "success",
        deployedTo: "prod",
        createdAt: "2024-11-02T12:30:00Z",
        createdBy: "Steve Rogers"
    },
    {
        id: 20,
        deploymentId: 20,
        serviceName: "BillingService",
        buildStatus: "failed",
        deployedTo: "stage",
        createdAt: "2024-11-03T11:45:00Z",
        createdBy: "Tina Fey"
    },
    {
        id: 21,
        deploymentId: 21,
        serviceName: "ShippingService",
        buildStatus: "success",
        deployedTo: "dev",
        createdAt: "2024-11-04T10:00:00Z",
        createdBy: "Uma Thurman"
    },
    {
        id: 22,
        deploymentId: 22,
        serviceName: "FeedbackService",
        buildStatus: "success",
        deployedTo: "prod",
        createdAt: "2024-11-05T09:15:00Z",
        createdBy: "Victor Hugo"
    },
    {
        id: 23,
        deploymentId: 23,
        serviceName: "AuditService",
        buildStatus: "failed",
        deployedTo: "stage",
        createdAt: "2024-11-06T14:30:00Z",
        createdBy: "Wendy Williams"
    },
    {
        id: 24,
        deploymentId: 24,
        serviceName: "ReviewService",
        buildStatus: "success",
        deployedTo: "dev",
        createdAt: "2024-11-07T13:45:00Z",
        createdBy: "Xander Cage"
    },
    {
        id: 25,
        deploymentId: 25,
        serviceName: "ConnectionService",
        buildStatus: "success",
        deployedTo: "prod",
        createdAt: "2024-11-08T12:00:00Z",
        createdBy: "Yara Shahidi"
    },
    {
        id: 26,
        deploymentId: 26,
        serviceName: "AnalysisService",
        buildStatus: "failed",
        deployedTo: "stage",
        createdAt: "2024-11-09T11:15:00Z",
        createdBy: "Zane Grey"
    },
    {
        id: 27,
        deploymentId: 27,
        serviceName: "RecommendationService",
        buildStatus: "success",
        deployedTo: "dev",
        createdAt: "2024-11-10T10:30:00Z",
        createdBy: "Amy Adams"
    },
    {
        id: 28,
        deploymentId: 28,
        serviceName: "ResourceService",
        buildStatus: "success",
        deployedTo: "prod",
        createdAt: "2024-11-11T09:45:00Z",
        createdBy: "Brandon Lee"
    },
    {
        id: 29,
        deploymentId: 29,
        serviceName: "TrackingService",
        buildStatus: "failed",
        deployedTo: "stage",
        createdAt: "2024-11-12T14:00:00Z",
        createdBy: "Chloe Kim"
    },
    {
        id: 30,
        deploymentId: 30,
        serviceName: "NotificationSystem",
        buildStatus: "success",
        deployedTo: "dev",
        createdAt: "2024-11-13T13:15:00Z",
        createdBy: "Daniel Craig"
    },
    {
        id: 31,
        deploymentId: 31,
        serviceName: "ReviewManagement",
        buildStatus: "success",
        deployedTo: "prod",
        createdAt: "2024-11-14T12:30:00Z",
        createdBy: "Emily Blunt"
    },
    {
        id: 32,
        deploymentId: 32,
        serviceName: "APIService",
        buildStatus: "failed",
        deployedTo: "stage",
        createdAt: "2024-11-15T11:45:00Z",
        createdBy: "Frank Underwood"
    },
    {
        id: 33,
        deploymentId: 33,
        serviceName: "GatewayManagement",
        buildStatus: "success",
        deployedTo: "dev",
        createdAt: "2024-11-16T10:00:00Z",
        createdBy: "Grace Kelly"
    },
    {
        id: 34,
        deploymentId: 34,
        serviceName: "SecurityService",
        buildStatus: "success",
        deployedTo: "prod",
        createdAt: "2024-11-17T09:15:00Z",
        createdBy: "Henry Cavill"
    },
    {
        id: 35,
        deploymentId: 35,
        serviceName: "ConnectivityService",
        buildStatus: "failed",
        deployedTo: "stage",
        createdAt: "2024-11-18T14:30:00Z",
        createdBy: "Isla Fisher"
    },
    {
        id: 36,
        deploymentId: 36,
        serviceName: "TaskManagement",
        buildStatus: "success",
        deployedTo: "dev",
        createdAt: "2024-11-19T13:45:00Z",
        createdBy: "Jack Black"
    },
    {
        id: 37,
        deploymentId: 37,
        serviceName: "LogService",
        buildStatus: "success",
        deployedTo: "prod",
        createdAt: "2024-11-20T12:00:00Z",
        createdBy: "Kerry Washington"
    },
    {
        id: 38,
        deploymentId: 38,
        serviceName: "DataService",
        buildStatus: "failed",
        deployedTo: "stage",
        createdAt: "2024-11-21T11:15:00Z",
        createdBy: "Leonardo DiCaprio"
    },
    {
        id: 39,
        deploymentId: 39,
        serviceName: "SystemMonitor",
        buildStatus: "success",
        deployedTo: "dev",
        createdAt: "2024-11-22T10:30:00Z",
        createdBy: "Maya Rudolph"
    },
    {
        id: 40,
        deploymentId: 40,
        serviceName: "HealthCheckService",
        buildStatus: "success",
        deployedTo: "prod",
        createdAt: "2024-11-23T09:45:00Z",
        createdBy: "Nathan Drake"
    }
];
