import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
//import { ordered as cakeOrdered } from "../cake/cakeSlice" 



const initialState = {
    loading: false,
    deployments: [],
    error: '',
    deploymentSelectedId:''    
}
export const getDeployments = createAsyncThunk('customers/getCustomers', async (axiosInstance) => {

    const response = await axiosInstance.get('/users');
    return response.data;
});

const deploymentSlice = createSlice({
    name: 'deployments',
    initialState,
    reducers: {
        /*setOpenAddCustomer(state, action) {
            state.openAddCustomer = action.payload;
        },
        setEditCustomer(state, action) {
            state.editCustomer = action.payload;
        },
        addCustomer: (state, action) => {
      
            state.customers.push(action.payload)
        },
        updateCustomer: (state, action) => {
            const { id, ...updatedFields } = action.payload; 
            const customerIndex = state.customers.findIndex(customer => customer.id === id);

            if (customerIndex !== -1) {
                state.customers[customerIndex] = {
                    ...state.customers[customerIndex], 
                    ...updatedFields,
                };
            }
        },
        setCustomerSelectedId: (state, action) => {
          
            state.customerSelectedId = action.payload; 
        },
        resetCustomerSelectedId: (state) => {
          
            state.customerSelectedId = ''; 
        }*/
    },
    //how to achieve buy 1 cake and get 1 ice cream free, how to effected on this state if another state has been changed
    extraReducers: (builder) => {
        //Combine 2 or more slice, which means, I something happend in this slice it'll take effect on another slice
        /* builder
           .addCase(cakeOrdered, (state) => {
             state.numOfIcecream--
           }) */
    },
})
export default deploymentSlice.reducer
export const { } = deploymentSlice.actions


