import React, {useState} from 'react';
import Stack from '@mui/material/Stack';
import NotificationsRoundedIcon from '@mui/icons-material/NotificationsRounded';
import NavbarBreadcrumbs from './NavbarBreadcrumbs';
import MenuButton from '../SideMenu/MenuButton';
import ColorModeIconDropdown from '../../theme/ColorModeIconDropdown';



const Header = () => {

  const [open, setOpen] = useState(false)

  const handleOpenNotificationCenter = () =>{
    setOpen(!open)
  }

  return (
    <React.Fragment>
      <Stack
        direction="row"
        sx={{
          display: { xs: 'none', md: 'flex' },
          width: '100%',
          alignItems: { xs: 'flex-start', md: 'center' },
          justifyContent: 'space-between',
          maxWidth: { sm: '100%', md: '1700px' },
          pt: 1.5,
        }}
        spacing={2}
      >
        <NavbarBreadcrumbs />
        <Stack direction="row" sx={{ gap: 1 }}>  
          <MenuButton showBadge aria-label="Open notifications" onClick={() => handleOpenNotificationCenter()}>
            <NotificationsRoundedIcon />
          </MenuButton>
          <ColorModeIconDropdown />
        </Stack>
      </Stack>
    </React.Fragment>
  );
};

export default Header;
