import React, { useEffect } from "react";
import { Box, Grid2, Typography } from '@mui/material';
import SonicDataGrid from "../Grid/SonicDataGrid";
import { getColumns,rows } from './deploymentGridData';
import { columnVisibilityModel } from './deploymentColumnDefinitionModel';

const DeploymentGrid = ({ title = "" , marginTop = 0 }) => {

    /*const dispatch = useDispatch();
    const { customers } = useSelector((state) => state.customers);
    const instance = useAxios(process.env.REACT_APP_DEMO_BASE_URL);*/



    const handleEdit = (row) => {

       /* dispatch(setEditCustomer(true))
        dispatch(setCustomerSelectedId(row.id))
        dispatch(setOpenAddCustomer(true))*/
    };

    const handleView = (row) => {


       /* dispatch(setEditCustomer(false))
        dispatch(setCustomerSelectedId(row.id))
        dispatch(setOpenAddCustomer(true))*/
    };

    const columns = getColumns(handleEdit, handleView);
    console.log("dd", columns)

  /*  useEffect(() => {
        if (customers.length === 0) {
            dispatch(getCustomers(instance))
        }
    }, [customers])*/







    return (


        <Box>
            {
                title !== "" &&
                (
                    <Typography variant="h6" gutterBottom>
                        {title}
                    </Typography>
                )
            }
            <Grid2 item xs={12} mt={marginTop} >
                <SonicDataGrid columns={columns} rows={rows} columnVisibilityModel={columnVisibilityModel} />
            </Grid2>
        </Box>

    )

}

export default DeploymentGrid
