// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Layout from './pages/Layout';
import Deployment from './components/Deployment/Deployment';





const App = () => {

  return (
    <React.Fragment>
      <Router>      
        <Routes>
          <Route path="/" element={<Layout><Deployment /></Layout>} />         
        </Routes>
      </Router>      
    </React.Fragment>
    
  );
}

export default App;
