import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';


const SonicDataGrid = ({columns,rows, onRowClick,columnVisibilityModel}) => {
   
     

    return (
        <DataGrid
            sx={{
                overflowX: 'auto',
                '& .MuiDataGrid-root': {
                    border: 'none',
                },
                '& .MuiDataGrid-scrollArea': {
                    overflow: 'hidden', // Hide extra overflow
                },
                '& .MuiDataGrid-scrollBar': {
                    width: '8px', // Set a narrower width for the scrollbar
                },
                '& .MuiDataGrid-scrollBar': {
                    '&.MuiDataGrid-scrollBar:hover': {
                        width: '12px', // Width on hover
                    },
                },
               
                '& .MuiDataGrid-cell:focus': {
                    border: 'none', // Remove the border when focused
                    outline: 'none', // Remove the outline on focus
                    
                },
            }}
            /*checkboxSelection*/
            /*onRowClick={(e)=>onRowClick(e)} */
            rows={rows}
            columns={columns}          
            getRowClassName={(params) =>
                params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
            }
            initialState={{
                pagination: { paginationModel: { pageSize: 20 } },
                columns: {
                    columnVisibilityModel: columnVisibilityModel
                  },
            }}
            pageSizeOptions={[10, 20, 50]}
            disableRowSelectionOnClick={true}
            /*disableColumnResize*/
            density="compact"
            slotProps={{
                filterPanel: {
                    filterFormProps: {
                        logicOperatorInputProps: {
                            variant: 'outlined',
                            size: 'small',
                        },
                        columnInputProps: {
                            variant: 'outlined',
                            size: 'small',
                            sx: { mt: 'auto' },
                        },
                        operatorInputProps: {
                            variant: 'outlined',
                            size: 'small',
                            sx: { mt: 'auto' },
                        },
                        valueInputProps: {
                            InputComponentProps: {
                                variant: 'outlined',
                                size: 'small',
                            },
                        },
                    },
                },
            }}
        />
    );
};

export default SonicDataGrid;
