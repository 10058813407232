import React, { useEffect } from "react";
import './Deployment.scss';
import { Box,  Grid2 } from '@mui/material';
import Search from "../Header/Search";
import DeploymentGrid from "./DeploymentGrid";


const Deployment = () => {

  
    return (
        <React.Fragment>
            <Box sx={{ padding: 2 }}>

                <Grid2 container spacing={2}>
                  
                    <Grid2 item xs={12}>
                        <Search />
                    </Grid2>
                </Grid2>

                <Grid2 item xs={12} marginTop={2} >
                    <DeploymentGrid />
                </Grid2>
            </Box>
        </React.Fragment>
    );
};

export default Deployment;
