import React from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { ThemeOptions } from '@mui/material/styles';

import { colorSchemes, typography, shadows, shape } from './themePrimitives.js';
import { inputsCustomizations } from './customizations/js/inputsCustomizations';
import { dataDisplayCustomizations } from './customizations/js/dataDisplayCustomizations';
import { feedbackCustomizations } from './customizations/js/feedbackCustomizations';
import { navigationCustomizations } from './customizations/js/navigationCustomizations';
import { surfacesCustomizations } from './customizations/js/surfacesCustomizations';



const AppTheme = ({ 
  children, 
  disableCustomTheme, 
  themeComponents 
}) => {
  const theme = React.useMemo(() => {
    if (disableCustomTheme) return {};
    
    return createTheme({
      cssVariables: {
        colorSchemeSelector: 'data-mui-color-scheme',
        cssVarPrefix: 'template',
      },
      colorSchemes,
      typography,
      shadows,
      shape,
      components: {
        ...inputsCustomizations,
        ...dataDisplayCustomizations,
        ...feedbackCustomizations,
        ...navigationCustomizations,
        ...surfacesCustomizations,
        ...themeComponents,
      },
    });
  }, [disableCustomTheme, themeComponents]);

  if (disableCustomTheme) {
    return <>{children}</>;
  }

  return (
    <ThemeProvider theme={theme} disableTransitionOnChange>
      {children}
    </ThemeProvider>
  );
};

export default AppTheme;
