
import deploymentReducer from './Deployment/deploymentSlice';
import { configureStore } from '@reduxjs/toolkit';


const store = configureStore({
  reducer: {   
    deployments: deploymentReducer,   
  },
});

export default store;
